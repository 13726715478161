import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as playgroundNzqExNPT85Meta } from "/vercel/path0/pages/dashboard/playground.vue?macro=true";
import { default as index44dsgVB6TrMeta } from "/vercel/path0/pages/dashboard/quests/[quest]/index.vue?macro=true";
import { default as index1bWrrxCFtsMeta } from "/vercel/path0/pages/dashboard/quests/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-playground",
    path: "/dashboard/playground",
    meta: playgroundNzqExNPT85Meta || {},
    component: () => import("/vercel/path0/pages/dashboard/playground.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-quests-quest",
    path: "/dashboard/quests/:quest()",
    component: () => import("/vercel/path0/pages/dashboard/quests/[quest]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-quests",
    path: "/dashboard/quests",
    meta: index1bWrrxCFtsMeta || {},
    component: () => import("/vercel/path0/pages/dashboard/quests/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]
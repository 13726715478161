import { createAuth0 } from "@auth0/auth0-vue";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const auth0 = createAuth0({
    domain: config.public.auth0_domain,
    clientId: config.public.auth0_client_id,
    authorizationParams: {
      redirect_uri: import.meta.client
        ? window.location.origin + "/"
        : "http://localhost:3000/",
    },
  });

  if (import.meta.client) {
    nuxtApp.vueApp.use(auth0);
  }

  addRouteMiddleware("auth", async () => {
    if (import.meta.client) {
      await auth0.checkSession();
      if (!auth0.isAuthenticated.value) {
        auth0.loginWithRedirect({
          appState: {
            target: useRoute().path,
          },
        });
      }
    }
  });
});

import { install as VueMonacoEditorPlugin } from "@guolao/vue-monaco-editor";

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.client) {
    nuxtApp.vueApp.use(VueMonacoEditorPlugin, {
      paths: {
        vs: "https://cdn.jsdelivr.net/npm/monaco-editor@0.50.0/min/vs",
      },
    });
  }
});

import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nitroApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();

  // If no sentry DSN set, ignore and warn in the console
  if (!config.public.SENTRY_DSN) {
    console.warn("Sentry DSN not set, skipping Sentry initialization");
    return;
  }

  if (!import.meta.client) return;
  // Initialize Sentry
  Sentry.init({
    app: nitroApp.vueApp,
    dsn: config.public.SENTRY_DSN,
    environment: config.public.SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.feedbackIntegration({
        colorSchema: "dark",
        autoInject: false,
      }),
    ],
    tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE,
    profilesSampleRate: config.public.SENTRY_PROFILER_SAMPLE_RATE,
    replaysSessionSampleRate: config.public.SENTRY_REPLAY_SAMPLE_RATE,
    replaysOnErrorSampleRate: config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE,
  });

  // Here comes the hooks
});

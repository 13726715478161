import revive_payload_client_Hq2THElGzQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_floating-vue@5.2.2_typescript@5.5.3_unocss@0.61.0_vite@5.3.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LjgcyND6Wb from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_floating-vue@5.2.2_typescript@5.5.3_unocss@0.61.0_vite@5.3.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ije1LReE2t from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_floating-vue@5.2.2_typescript@5.5.3_unocss@0.61.0_vite@5.3.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_B1oyUdvYti from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_floating-vue@5.2.2_typescript@5.5.3_unocss@0.61.0_vite@5.3.1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_6nl1UiN6oj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_floating-vue@5.2.2_typescript@5.5.3_unocss@0.61.0_vite@5.3.1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_h6laBVYE3I from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_floating-vue@5.2.2_typescript@5.5.3_unocss@0.61.0_vite@5.3.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_YYr6chNlNL from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_nuxt@3.12.2_vite@5.3.1_vue@3.4.29/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_7gIJLuQJPn from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_nuxt@3.12.2_vite@5.3.1_vue@3.4.29/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_XjsgeUvr0b from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.17.0_nuxt@3.12.2_vite@5.3.1_vue@3.4.29/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_he0U0CYwZq from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UamCwgYX3t from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_floating-vue@5.2.2_typescript@5.5.3_unocss@0.61.0_vite@5.3.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/vercel/path0/plugins/auth.ts";
import code_ZzPF6OlJoW from "/vercel/path0/plugins/code.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
export default [
  revive_payload_client_Hq2THElGzQ,
  unhead_LjgcyND6Wb,
  router_ije1LReE2t,
  payload_client_B1oyUdvYti,
  check_outdated_build_client_6nl1UiN6oj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_h6laBVYE3I,
  slideovers_YYr6chNlNL,
  modals_7gIJLuQJPn,
  colors_XjsgeUvr0b,
  plugin_client_he0U0CYwZq,
  chunk_reload_client_UamCwgYX3t,
  auth_vT9JWWT9pN,
  code_ZzPF6OlJoW,
  sentry_3AyO8nEfhE
]
<template>
  <div class="bg-white dark:bg-zinc-900 min-h-screen">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <USlideovers />
  </div>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import * as Sentry from "@sentry/vue";

useHead({
  // or as a function
  titleTemplate: (pageTitle) => {
    return pageTitle ? `${pageTitle} - CodeQuest RPG` : "CodeQuest RPG";
  },
});

const auth0 = useAuth0();

watchEffect(() => {
  if (auth0 && auth0.isAuthenticated && auth0.isAuthenticated.value) {
    createWidget();
  }
});

const createWidget = () => {
  return Sentry.getFeedback()?.createWidget();
};
</script>

<style>
:root {
  --header-height: 4rem;
}
</style>
